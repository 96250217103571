import moment from 'moment'

import { ActivityTracking } from 'models/activity-tracking'
import { ActivityTrackingResponse } from 'models/http'
import { ActivityTrackingFilter } from 'models/filter'

export const mockActivityTracking = [
  {
    id: 1,
    timestamp: moment().subtract(2, 'days').toString(),
    activity: 'Created',
    systemArea: 'Tenant',
    itemChanged: '51-Goal-Dr-Ontario-MY6NL2',
    previousValue: [
      {
        label: 'OCPI Sharing',
        value: 'No',
      },
      {
        label: 'Rate',
        value: 200, // in cents
      },
      {
        label: 'Curtailment Adjustment',
        value: false,
      },
      { label: 'Service Status', value: 'Awaiting Commissioning' },
    ],
    newValue: [
      {
        label: 'OCPI Sharing',
        value: 'Yes',
      },
      {
        label: 'Rate',
        value: 300, // in cents
      },
      {
        label: 'Curtailment Adjustment',
        value: true,
      },
      { label: 'Service Status', value: 'Troubleshooting' },
    ],
    modifiedBy: 'Josh Dean',
    modifiedByEmail: 'john.dean@gmail.com',
  },
  {
    id: 2,
    timestamp: moment().subtract(1, 'days'),
    activity: 'Updated',
    systemArea: 'Listing',
    itemChanged: 'DP01',
    previousValue: [
      {
        label: 'OCPI Sharing',
        value: 'Yes',
      },
      {
        label: 'Rate',
        value: 200, // in cents
      },
      { label: 'Service Status', value: 'Active' },
    ],
    newValue: [
      {
        label: 'OCPI Sharing',
        value: 'No',
      },
      {
        label: 'Rate',
        value: 300, // in cents
      },
      { label: 'Service Status', value: 'Under Repair' },
    ],
    modifiedBy: 'Mike Jameson',
    modifiedByEmail: 'mike.jameson@gmail.com',
  },
  {
    id: 3,
    timestamp: moment().subtract(2, 'days'),
    activity: 'Updated',
    systemArea: 'Users',
    itemChanged: 'aaronscott@gmail.com',
    previousValue: [
      {
        label: 'Role',
        value: 'User',
      },
    ],
    newValue: [
      {
        label: 'Role',
        value: 'Manager',
      },
    ],
    modifiedBy: 'Amy Lee',
    modifiedByEmail: 'amy.lee@gmail.com',
  },
  {
    id: 4,
    timestamp: moment().subtract(3, 'days'),
    activity: 'Created',
    systemArea: 'Listing',
    itemChanged: 'PL05',
    previousValue: [
      { label: 'Name', value: 't123' },
      {
        label: 'Time of Day Price Weekday',
        value: [...Array(8).fill(100), ...Array(7).fill(150), ...Array(9).fill(80), ...Array(24).fill(200)],
      },
      {
        label: 'OCPI Sharing',
        value: 'Yes',
      },
      {
        label: 'Rate',
        value: 200, // in cents
      },
      { label: 'Service Status', value: 'Active' },
      {
        label: 'Role',
        value: 'User',
      },
    ],
    newValue: [
      { label: 'name', value: 't456' },
      { label: 'Time of Day Price Weekday', value: [...Array(24).fill(200), ...Array(24).fill(300)] },
      {
        label: 'OCPI Sharing',
        value: 'Yes',
      },
      {
        label: 'Rate',
        value: 300, // in cents
      },
      { label: 'Service Status', value: 'Troubleshooting' },
      {
        label: 'Role',
        value: 'Manager',
      },
    ],
    modifiedBy: 'Danielle Chavez',
    modifiedByEmail: 'Danielle.Chavez@gmail.com',
  },
  {
    id: 5,
    timestamp: moment().subtract(4, 'days'),
    activity: 'Updated',
    systemArea: 'Pricing Schema',
    itemChanged: 'Single Pricing DP01',
    previousValue: [
      {
        label: 'Rate',
        value: 200, // in cents
      },
      { label: 'Remittance Email(s)', value: 'ugo@swtchenergy.com' },
    ],
    newValue: [
      { label: 'Rate', value: 350 },
      { label: 'Remittance Email(s)', value: 'nic@swtchenergy.com' },
    ],
    modifiedBy: 'Mike Jameson',
    modifiedByEmail: 'mike.jameson@gmail.com',
  },
  {
    id: 6,
    timestamp: moment().subtract(4, 'days'),
    activity: 'Updated',
    systemArea: 'Pricing Schema',
    itemChanged: 'Single Pricing PL02',
    previousValue: [
      {
        label: 'Price Per Unit Cents',
        value: '0',
      },
      {
        label: 'Time Of Day Price Weekday',
        value:
          '[nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil]',
      },
      {
        label: 'Time Of Day Price Weekend',
        value:
          '[nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil]',
      },
      { label: 'Service Status', value: 'Awaiting Commissioning' },
    ],
    newValue: [
      {
        label: 'Price Per Unit Cents',
        value: '0',
      },
      {
        label: 'Time Of Day Price Weekday',
        value:
          '[nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil]',
      },
      {
        label: 'Time Of Day Price Weekend',
        value:
          '[nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil, nil]',
      },
      { label: 'Service Status', value: 'Terminated' },
    ],
    modifiedBy: 'Mike Jameson',
    modifiedByEmail: 'mike.jameson@gmail.com',
  },
] as ActivityTracking[]

export const mockActivityTrackingResponse = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  filter: ActivityTrackingFilter,
) => {
  let filterMockActivityTracking = mockActivityTracking

  if (startDate && endDate) {
    filterMockActivityTracking = mockActivityTracking.filter((activity) => {
      return moment(activity.timestamp).isBetween(startDate, endDate)
    })
  }

  if (filter.systemAreas && filter.systemAreas.length > 0) {
    filterMockActivityTracking = filterMockActivityTracking.filter((activity) => {
      return filter.systemAreas && filter.systemAreas.includes(activity.systemArea)
    })
  }

  if (filter.listings && filter.listings.length > 0) {
    filterMockActivityTracking = filterMockActivityTracking.filter((activity) => {
      return filter.listings && filter.listings.map((listing) => listing.title).includes(activity.itemChanged)
    })
  }

  return {
    data: filterMockActivityTracking,
    pagination: {
      currentPage: filter.page,
      totalEntries: mockActivityTracking.length,
      perPage: 4,
    },
  } as ActivityTrackingResponse
}
